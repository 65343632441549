export const environment = {
    production: true,
    firebase : {
        apiKey: "AIzaSyCBcl4cLsd1ZPinPqy1Ws3Yww9qXWJHX3g",
        authDomain: "eyeconicpixelcom.firebaseapp.com",
        databaseURL: "https://eyeconicpixelcom.firebaseio.com",
        projectId: "eyeconicpixelcom",
        storageBucket: "eyeconicpixelcom.firebasestorage.app",
        messagingSenderId: "629672555793",
        appId: "1:629672555793:web:890771b56b6fbde8e888b0"
        } 
  };