<div class="relative w-full mx-auto">
  <div class="flex">
      <video #videoPlayer controls loop autoplay muted class="min-w-full min-h-full lg:h-full pb-10">
      <source src="/assets/img/tech.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
      <div class="col-span-3 text-left mt-4 pb-5 md:text-3xl md:mt-4 text-sm lg:text-3xl pt-8 text-white absolute top-1  md:top-20 md:mx-10 md:px-10 md:pt-5 px-1 mx-3 text-wrap  block md:w-1/2 shadow-xl bg-black bg-opacity-30 lg:mt-20">
        {{ imgDesc}}
        <p class="min-w-fit md:text-lg text-sm text-left">
         {{subtext}}
        </p>
        <a [routerLink]="['/portfolio']" routerLinkActive="router-link-active"  class="bg-orange-700 rounded p-2 text-white text-lg" rou>Explore Now >></a>
      </div>
  </div>

  <!-- Navigation Buttons -->
  <!-- <button (click)="prevImage()"
    class="absolute md:top-1/2 top-1/3 -my-4 left-0 transform -translate-y-1/2 bg-gray-800 text-white p-2">
    &#10094;
  </button>
  <button (click)="nextImage()"
    class="absolute md:top-1/2 top-1/3 -my-4 right-0 transform -translate-y-1/2 bg-gray-800 text-white p-2">
    &#10095;
  </button> -->
</div>