<div class="container max-w-fit mx-auto md:mx-auto mt-10 my-3">
    <div class="flex flex-col md:flex-row  max-w-fit justify-items-center space-y-2">
        <button class="w-80 border border-cyan-900 mx-5 text-center shadow-lg p-3 bg-blue-700 text-white hover:bg-orange-600 rounded" (click)="filterImages('All')" [class.active]="selectedCategory === 'All'">All</button>
        <button class="w-80 border border-cyan-900 mx-5 text-center shadow-lg p-3 bg-blue-700 text-white hover:bg-orange-600 rounded" (click)="filterImages('LandingPage Design')" [class.active]="selectedCategory === 'LandingPage Design'">Landing Page Design</button>
        <button class="w-80 border border-cyan-900 mx-5 text-center shadow-lg p-3  bg-blue-700 text-white hover:bg-orange-600 rounded" (click)="filterImages('Website Design')" [class.active]="selectedCategory === 'Website Design'">Website Design</button>
    </div>

    <div class="grid grid-rows-1 md:grid-cols-3 gap-2 mt-10 justify-items-center mx-4 min-w-96">
        <div class="min-w-full h-fit items-center" *ngFor="let work of filteredItems;let i=index">
             <a  routerLinkActive="router-link-active" [href]="work.url" alt="websiteUrl" target="_blank" routerLinkActive="router-link-active" > <img [src]="work.thumbImgUrl" alt="img1" class="border-slate-500 border p-2 rounded " [alt]="work.category"/></a>
        </div>
    </div>
</div>
