<div class="w-full mx-automin-h-full pt-3">
    <div appSectionHeading class="my-5">
        Technologies <span appHighlightedText>We Offer</span>
    </div>
    <div class="pt-10 px-10 pb-5  bg-black mt-3">
        <ul *ngFor="let techno of tech, let i =index" class="grid grid-rows-1 lg:grid lg:grid-cols-4 grid-cols-1 sm:grid sm:grid-cols-2 space-y-2 mx-14 sm:mx-4 place-items-center">
            <li>
                <img [src]="techno.url" />
            </li>
            <li>
                <img [src]="techno.url2" />
            </li>
            <li>
                <img [src]="techno.url3" />
            </li>
            <li>
                <img [src]="techno.url4" />
            </li>
            <li>
                <img [src]="techno.url5" />
            </li>
            <li>
                <img [src]="techno.url6" />
            </li>
            <li>
                <img [src]="techno.url7" />
            </li>
            <li>
                <img [src]="techno.url8" />
            </li>
            <li>
                <img [src]="techno.url9" />
            </li>
            <li>
                <img [src]="techno.url10" />
            </li>
            <li>
                <img [src]="techno.url11" />
            </li>
            <li>
                <img [src]="techno.url12" />
            </li>
        </ul>
    </div>
</div>