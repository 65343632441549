import { Component,Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighlightedTextDirective } from 'src/app/share/shared/highlighted-text.directive';
@Component({
  selector: 'app-aboutus',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent {

}
