<div class="relative h-full">
    <div appSectionHeading>
        Our <span appHighlightedText>Services</span>
    </div>
    <div class="container mx-auto">
        <div class="grid md:grid-cols-3 grid-cols-1 gap-1 mx-2 my-2 mt-10">
            <div *ngFor="let services of features,let i= index"
                class="text-center p-10 border-2 border-inherit hover:bg-sky-100">
                <img [src]="services.url" class="mx-auto max-w-32" />
                <h2>{{services.title}}</h2>
                <p>{{services.desc}}</p>
            </div>
        </div>
    </div>