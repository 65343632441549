<div class="bg-[url('assets/img/innerPageHeader.jpg')] bg-cover bg-center bg-blue-800 min-h-fit pb-4 md:h-64 w-full">
    <div class="flex flex-col md:flex-row max-w-screen-lg mx-8 lg:mx-auto">
        <div>
            <div class="text-2xl text-white mx-auto md:mx-16 pt-10 w-80">{{ pageTitle }}
                <p class="text-lg pt-5 mx-1">{{pageSubtitle}}
                    {{pageDesc}}
                </p>
                </div>
        </div>
        <div class="mt-2 justify-items-center lg:justify-items-end lg:mx-3 w-full">
            <img [src]="pageHeaderImg" class="rounded w-96 h-52 mt-3"/>
        </div>
    </div>

</div>
