<div class="container mx-auto mt-14 mb-10">
    <div appSectionHeading>
        How It Is <span appHighlightedText>Going</span>
        <span class="border-b-2 border-slate-500 w-28 h-2 block mx-auto"></span>
    </div>
    <div class="bg-none md:bg-none relative mt-10 lg:bg-[url('/assets/img/Vector-1.png')] bg-no-repeat min-w-full  bg-left-top flex flex-col lg:flex lg:flex-col-3">

        <div
            class="md:grid md:grid-cols-3 grid grid-cols-1 lg:grid lg:grid-cols-5 lg:space-x-0 lg:w-full space-y-2 lg:relative  space-x-5 w-full place-items-center py-5">
            <div class="w-32 relative xl:h-60">
                <div
                    class="border-2 border-orange-500/75 rounded-full p-3 w-24 bg-white mx-4 md:p-5 lg:h-24 lg:absolute lg:-mt-36 lg:ml-6 lg:mx-3 xl:-mt-14">
                    <img src="/assets/img/icons/info-gathering.png" class="mx-auto mt-0 w-auto lg:w-14 lg:mt-0 " />
                </div>
                <p class="text-black text-center text-lg pt-5 xl:pt-12"> Info- Gathering</p>
            </div>
            <div class="w-32 relative lg:h-60 place-content-end lg:text-center">
                <div
                    class="border-2 border-orange-500/75 rounded-full p-3 w-24 bg-white mx-4 md:p-5 lg:h-24 lg:absolute lg:mx-16 lg:-mt-24">
                    <img src="/assets/img/icons/Plan.png" class="mx-auto mt-0 w-10  lg:mt-0  lg:w-10" />
                </div>
                <p class="text-black text-center text-lg pt-5 lg:mt-30 lg:px-20"> Planning</p>
            </div>

            <div class="w-32 relative lg:h-60 place-content-end lg:place-content-start xl:place-content-end lg:text-center xl:h-60">
                <div class="border-2 border-orange-500/75 rounded-full p-3 w-24 bg-white mx-4 md:p-5 lg:h-24 lg:absolute lg:mx-16 lg:-mt-10 xl:mx-32 xl:-mt-10 xl:h-24">
                    <img src="/assets/img/icons/websiteDesign.png" class="lg:w-9 mx-auto mt-0 w-auto lg:mt-2 xl:mt-3" />
                </div>
                <p class="text-black text-center text-lg pt-5 lg:px-16 lg:pt-16 xl:px-32"> Designing </p>
            </div>

            <div class="w-32 relative lg:h-60 place-content-top lg:place-content-end xl:place-content-start lg:text-center xl:h-32">
                <div
                    class="border-2 border-orange-500/75 rounded-full p-3 w-24 bg-white mx-4 md:p-5 lg:h-24  lg:absolute lg:mx-16 lg:-mt-28 xl:-mt-16 xl:mx-20">
                    <img src="/assets/img/icons/development.png" class="lg:w-10 mx-auto mt-0 w-auto lg:mt-2" />
                </div>
                <p class="text-black text-center text-lg pt-5 lg:px-16 lg:pt-2 xl:pt-10 xl:px-20"> Development</p>
            </div>
            <div class="w-32 relative lg:h-60 place-content-bottom lg:text-center">
                <div
                    class="border-2 border-orange-500/75 rounded-full p-3 w-24 bg-white mx-4 md:p-5 lg:h-24 lg:absolute lg:mx-16 lg:-mt-10 xl:mt-12">
                    <img src="/assets/img/icons/launch.png" class="lg:w-10 mx-auto mt-0 w-auto  lg:mt-2" />
                </div>
                <p class="text-black text-center text-lg pt-5 lg:px-16 lg:pt-16 xl:px-20 xl:pt-40">Launch</p>
            </div>
        </div>

    </div>